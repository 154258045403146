@mixin Popover {
    $min-width: 350px;
    $popover-color: rgba(lighten($reference-color, 45%), 0.5);
    $blur-size: 5px;
    $border-radius: 10px;
    .bs-popover-bottom > .popover-arrow::after {
        border-bottom-color: lighten($reference-color, 28%);
    }
    .popover {
        font-size: 1em;
        box-shadow: 0 0 10px $reference-color;
        border: none;
        min-width: $min-width;
        background-color: transparent;
        top: 10px !important;
        z-index: 1000;
        border-radius: $border-radius;
        backdrop-filter: blur($blur-size);
        .popover-header {
            color: #fff;
            padding: 20px;
            overflow: hidden;
            font-size: 1em;
            text-align: center;
            border-bottom: none;
            background-color: $popover-color;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            &:before {
                display: none;
            }
        }
        .popover-body {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            background-color: $popover-color;
        }
        .media {
            display: flex;
            align-items: center;
            font-size: 1em;
            padding: 10px 0;
            border-bottom: 1px solid lighten($reference-color, 5%);
            &:last-child {
                border: none;
            }
            img {
                border: 1px solid;
                vertical-align: middle;
            }
            .media-body {
                h5 {
                    font-size: 1.2em;
                }
                color: #fff;
                p {
                    color: $default-text-color;
                }
            }
        }
    }

    .fa-layers {
        vertical-align: middle;
    }
    .fa-layers-counter {
        position: absolute;
        top: 5px;
        right: -12px;
        display: flex;
        width: 50px;
        justify-content: center;
        align-items: center;
        height: 50px;
        font-size: 3em;
        color: #fff;
        border-radius: 50%;
    }
}
