@mixin Button {
    .btn {
        font-size: 1em;
        outline: none;
        box-shadow: none;
        border: none;
        padding: 10px 20px;
        position: relative;
        &.btn-sm {
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
        }
        &.btn-icon {
            padding: 10px;
            svg {
                width: 24px;
                margin-right: 3px;
            }
        }
        &:disabled {
            background-color: lighten($reference-color, 5%) !important;
            color: lighten($reference-color, 45%);
            filter: brightness(100%) !important;
        }
        &.btn-retry {
            color: #fff;
            background-color: $retry-color;
            &:hover {
                background-color: lighten($retry-color, 10%);
            }
        }
        &.btn-secondary {
            background-color: lighten($reference-color, 10%);
            &:hover {
                background-color: lighten($reference-color, 20%);
            }
            &:active,
            &.active {
                background-color: lighten($reference-color, 25%) !important;
            }
        }
        &.btn-primary {
            background-color: var(--accentColor);
            &:hover {
                filter: brightness(110%);
            }
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active {
                background-color: var(--accentColor);
                filter: brightness(120%);
            }
        }
        @keyframes slide {
            0%,
            100% {
                right: 10px;
            }
            50% {
                right: 15px;
            }
        }
    }
}
