@mixin ComponentLoader {
    .loader {
        p {
            margin: 0;
            bottom: 0;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            white-space: nowrap;
            animation: pulse 2s linear infinite;
        }
        .indicator {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(5);
        }
        .indicator svg polyline {
            fill: none;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
        .indicator svg polyline#back {
            stroke: lighten($reference-color, 10%);
        }
        .indicator svg polyline#front {
            stroke: var(--accentColor);
            stroke-dasharray: 12, 36;
            stroke-dashoffset: 48;
            animation: dash 1s linear infinite;
        }
        @keyframes dash {
            62.5% {
                opacity: 0;
            }
            to {
                stroke-dashoffset: 0;
            }
        }
        @keyframes pulse {
            0% {
                opacity: 0.2;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0.2;
            }
        }
    }
}
