#support {
    display: flex;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    height: 100vh;
    form {
        margin-top: 50px;
        .form-control {
            padding: 30px;
            background-color: lighten($reference-color, 2%) !important;
        }
    }
    /* Change autocomplete styles in WebKit */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        background-color: #000;
        outline: none;
        border-radius: 4px;
        overflow: hidden;
        -webkit-text-fill-color: #fff;
        box-shadow: 0 0 0px 1000px lighten($reference-color, 2%) inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}
