@mixin DownloadBanner {
    .download-banner {
        display: flex;
        font-family: "Quicksand", sans-serif;
        align-items: center;
        background-color: lighten($reference-color, 5%);
        .container {
            padding: 100px 0;
            .row {
                display: flex;
                align-items: center;
                .col-10 {
                    margin: 0;
                    padding: 0;
                    h2 {
                        font-family: "Quicksand", sans-serif;
                        font-weight: 600;
                    }
                    p {
                        color: $default-text-color;
                        margin: 0;
                    }
                }
                .btn {
                    border-radius: 100px;
                }
            }
        }
    }
}
