@mixin ComponentPaginator {
    .paginator {
        
        color: $default-text-color;
        .paginator-info {
            margin-left: 5px;
        }
        .paginator-header {
            margin-bottom: 5px;
            white-space: nowrap;
            .dropdown {
                display: inline;
                .btn-transparent {
                    background-color: lighten($reference-color, 2%);
                    padding-right: 13px;
                    padding-left: 13px;
                    color: $default-text-color;
                    border: 1px solid lighten($reference-color, 15%);
                    &:after {
                        margin-left: 15px;
                    }
                    &:focus {
                        background-color: lighten($reference-color, 5%);
                        border: 1px solid lighten($reference-color, 15%) !important;
                    }
                }
                .dropdown-menu {
                    box-shadow: none;
                    min-width: 100%;
                    background-color: lighten($reference-color, 2%);
                    .dropdown-item {
                        background-color: lighten($reference-color, 2%);
                        padding: 5px 13px;
                        width: inherit !important;
                        &:hover {
                            background-color: var(--accentColor);
                        }
                    }
                }
            }
            .form-group {
                margin: 0;
            }
            label {
                display: inline;
                margin-right: 10px;
            }
            .form-control {
                padding: 12px 15px!important;
                margin-left: 10px;
                width: auto;
                border: 1px solid lighten($reference-color, 15%)!important;
            } 
        }
    }
}
