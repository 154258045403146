@mixin CardDeck {
    .card-deck {
        margin: 0;
        .card {
            margin: 5px;
            margin-top: 0;
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
