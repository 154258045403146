@mixin ComponentChartTableCard {
    .chart-table-card {
        background: linear-gradient(lighten($reference-color, 4%), $content-bg-color);
        .card-header {
            text-align: center;
            font-family: Quicksand, sans-serif;
            font-size: 12px;
            font-weight: 800px;
            text-transform: capitalize;
        }
        canvas {
            display: inline-block;
            margin: 0 auto;
        }
    }
}
