:root {
    --accentColor: #9013fe;
}
html,
body {
    scroll-behavior: smooth;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    height: 100vh;
    color: $default-text-color;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $content-bg-color;
}
html {
    font-size: 12px;
}
#root {
    height: 100vh;
}
#report {
    margin-top: 112px;
}
strong {
    color: #fff;
}

.row {
    margin-right: -5px;
    margin-left: -5px;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    height: 100%;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 10px;
}
.white {
    color: #fff;
}
h1,
h2,
h3,
h4,
h5 {
    color: #fff;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
}
h1,
h2 {
    margin-bottom: 15px;
    span {
        color: #9013fe;
    }
}

footer {
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    margin: 50px;
    p {
        margin: 0;
        color: #fff;
    }
}

* {
    //cursor: url('cursor.png'), auto !important;
    user-select: none;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-color;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        background: linear-gradient($content-bg-color, $scrollbar-track-color, $content-bg-color);
        //background-color: $scrollbar-track-color;
        border-radius: 6px;
    }
    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: var(--accentColor);
        }
    }
}
.v-scroll {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    height: 100vh;
    z-index: 1;
    /* width */
    &::-webkit-scrollbar {
        position: relative;
        z-index: 1000;
        width: 4px;
        height: 4px;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
        position: relative;
        z-index: 1000;
        background-color: $scrollbar-thumb-color;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        position: relative;
        z-index: 1000;
        background: rgba(#12121c, 0.95);
        //background-color: $scrollbar-track-color;
        border-radius: 6px;
    }
    &:hover {
        &::-webkit-scrollbar-thumb {
            position: relative;
            z-index: 1000;
            background-color: var(--accent-color);
        }
    }
}

.top {
    overflow: hidden;
}

.number {
    font-family: "Lato", sans-serif !important;
    font-weight: 600 !important;
}
