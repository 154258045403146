@mixin Dropdown {
    $menu-color: lighten($content-bg-color, 2%);
    .dropdown {
        position: relative;
        z-index: 100000;
        .dropdown-menu {
            box-shadow: 0 0 15px $reference-color;
            background-color: $menu-color;
            border-color: lighten($menu-color, 2%);
            margin-top: 7px;
        }
        .dropdown-toggle {
            background: transparent;
        }
        .dropdown-item,
        .dropdown-menu {
            font-size: 1em;
            color: $default-text-color;
            svg {
                color: $default-text-color;
                margin-right: 6px;
            }
        }
        .dropdown-menu:after {
            border-bottom: 8px solid $menu-color;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            content: "";
            display: inline-block;
            position: absolute;
            left: 100%;
            margin-left: -23%;
            top: -7px;
        }
        .dropdown-item {
            &:hover {
                color: #fff;
                background-color: var(--accentColor);
                font-weight: bold;
                svg {
                    color: #fff;
                }
            }
        }
        .dropdown-divider {
            border-color: lighten($content-bg-color, 5%);
        }
    }
}
