@mixin ProgressBar {
    .progress {
        background-color: $reference-color;
        height: 4px;
        margin-top: 5px;
        .progress-bar {
            border-radius: 4px;
            background-color: var(--accentColor) !important;
        }
    }
}
