@mixin ComponentStatistics {
    #statistics {
        margin-bottom: 50px;
        h2 {
            text-align: center;
            margin: 50px 0;
        }
        .statistic {
            display: block;
            .statistic-container {
                display: block;
                position: relative;
            }
            position: relative;
            background-color: #1c1c2c;
            border-radius: 10px;
            h2 {
                background-color: #282840;
                padding: 25px 0;
                margin: 25px -10px;
            }
            background-color: #1c1c2c;
            padding: 5px 10px;
            .list-group-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #fff;
                margin: 10px 0;
                padding: 10px;
                padding-left: 20px;
                background-color: #202033;
                span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .badge {
                    font-family: "Montserrat";
                    background-image: linear-gradient(45deg, #bb0ffa, #47c2ff);
                    padding: 10px 20px;
                    font-size: 0.9em;
                    border-radius: 50px;
                }
            }
        }
    }
}
