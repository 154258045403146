@mixin Modal {
    .modal-open .window {
        filter: blur(2px);
    }
    .fullscreen {
        .modal-backdrop {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: auto;
            height: auto;
            border-radius: 0;
            z-index: 0;
        }
    }
    .modal-backdrop {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: auto;
        height: auto;
        border-radius: 8px;
        background-color: darken($reference-color, 5%);
        &.show {
            opacity: 0.75;
        }
    }
    .modal-header {
        .btn-close {
            color: #fff !important;
        }
        .btn-close:not(:disabled):not(.disabled):hover,
        .btn-close:not(:disabled):not(.disabled):focus {
            background-color: transparent;
            opacity: 1;
        }
    }
    .modal {
        -webkit-app-region: no-drag;
        left: $window-shadow-size;
        right: $window-shadow-size;
        top: $window-shadow-size;
        bottom: $window-shadow-size;
        border-radius: $window-border-radius;
        width: auto;
        height: auto;
        margin: 0 auto;
        max-width: 720px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        z-index: 0;
        .modal-dialog {
            .modal-content {
                .v-scroll {
                    .col {
                        margin: 0;
                    }
                    max-height: 320px;
                    border: 1px solid $content-bg-color;
                    padding: 10px;
                    border-radius: 4px;
                    background-color: $reference-color;
                }
                border-radius: $window-border-radius;
                background-color: rgba($content-bg-color, 0.9);
                .close {
                    cursor: default !important;
                    outline: none;
                    text-shadow: none;
                    color: $default-text-color;
                    &:hover {
                        color: #fff;
                    }
                }
                .modal-header {
                    color: #fff;
                }
                .modal-body {
                    line-height: 2em;
                    .form-control {
                        margin: 0;
                        border: 1px solid lighten($reference-color, 15%);
                        background-color: lighten($reference-color, 5%);
                        border-radius: 3px;
                        padding: 10px;
                        display: block;
                        height: 40px;
                    }
                    textarea {
                        height: 100px!important;
                    }
                }
                .modal-header,
                .modal-body,
                .modal-footer {
                    border: none;
                    background-color: transparent;
                }
                .modal-footer {
                    .progress-container {
                        flex: auto;
                        &.uploading {
                            width: 65%;
                        }
                    }
                }
            }
        }
    }
}
