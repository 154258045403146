#download {
    height: 100vh;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 30px;
    .image {
        height: 480px;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .container {
        height: auto;
        .row {
            height: auto;
        }
    }
}
