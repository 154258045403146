@mixin ComponentTileCard {
    .tile-card {
        display: flex;
        font-family: Quicksand, sans-serif;
        font-size: 12px;
        align-items: center;
        padding: 10px;
        gap: 10px;
        height: 62px;
        width: 100%;
        border-radius: 4px;
        font-weight: 600;
        background: linear-gradient(90deg, $content-bg-color, lighten($reference-color, 4%));

        .icon {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border: 3px solid var(--accentColor);
            border-radius: 24px;
            svg {
                color: var(--accentColor);
                font-size: 20px;
                transform: rotateY(180deg);
            }
        }
        .left {
            width: 100%;
            overflow: hidden;
            .title {
                color: #fff;
                font-weight: 700;
            }
            .weapon {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .buff {
            }
        }
        .right {
            width: 100%;
            overflow: hidden;
            text-align: right;
            .target {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .damage {
                font-family: Lato, sans-serif;
                color: #fff;
                svg {
                    color: #ff7700;
                    margin-left: 5px;
                }
            }
        }
        &.reverse {
            flex-direction: row-reverse;
            background: linear-gradient(90deg, lighten($reference-color, 4%), $content-bg-color);
            .right {
                text-align: left;
                .damage {
                    display: inline-flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    svg {
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }
            }
            .left {
                text-align: right;
            }
        }
    }
}
