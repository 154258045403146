.btn-outline {
    background-color: transparent;
    border: 3px solid;
    border-color: inherit;
    border-radius: 100px;
    padding: 10px 30px;
    margin: 0 auto;
    display: inline-block;
    text-align: center;
    color: #000;
    &.btn-white {
        font-weight: 600;
        color: #fff;
        &:hover {
            background-color: #fff;
            color: $reference-color;
        }
        &:disabled {
            $brightness: 12%;
            border-color: lighten($reference-color, $brightness);
            color: lighten($reference-color, $brightness * 2);
            cursor: not-allowed;
            &:hover {
                color: lighten($reference-color, $brightness * 2);
                background-color: inherit;
            }
        }
    }
}
.btn {
    white-space: nowrap;
    &.btn-download {
        background: linear-gradient(-135deg, #bb0ffa, #47c2ff) no-repeat;
        color: #fff;
        padding: 15px 25px;
        background-origin: border-box;
        border-radius: 4px;
        overflow: hidden;
        animation: pulse 4s infinite;
        @keyframes pulse {
            0% {
                box-shadow: 0 0 40px #822eff;
            }
            50% {
                box-shadow: 0 0 40px #40c4ff;
            }
            100% {
                box-shadow: 0 0 40px #822eff;
            }
        }
    }
}
#login {
    border-radius: 100px;
    text-align: center;
    padding: 10px 30px;
    border: 3px solid #fff;
    &:hover {
        background-color: #fff;
        color: #000;
    }
}
