#paypal {
    text-align: center;
    .container {
        max-width: 720px;
    }
    .fa-beat {
        animation: fa-beat 5s ease infinite;
    }
    @keyframes fa-beat {
        0% {
            transform: scale(1);
        }
        5% {
            transform: scale(1.25);
        }
        20% {
            transform: scale(1);
        }
        30% {
            transform: scale(1);
        }
        35% {
            transform: scale(1.25);
        }
        50% {
            transform: scale(1);
        }
        55% {
            transform: scale(1.25);
        }
        70% {
            transform: scale(1);
        }
    }
}
