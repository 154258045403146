@mixin Features {
    #features {
        .feature {
            overflow: hidden;
            position: relative;
            padding: 50px 0;
            img {
                float: right;
                left: 0;
            }
            &.alt {
                //clip-path: polygon(25% 0, 100% 20%, 100% 80%, 75% 100%, 0 80%, 0 20%);
                img {
                    float: left;
                    right: 0;
                }
            }
        }
    }
}
