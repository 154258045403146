@mixin Table {
    .react-bs-table-tool-bar {
        margin-top: 10px;
        input {
            border-color: $reference-color;
            background-color: $reference-color;
        }
    }
    .react-bs-table-bordered {
        border: none;
    }
    .card {
        svg {
            &.fa-sort-up,
            &.fa-sort-down {
                color: lighten($table-sort-color, 10%) !important;
            }
            &.fa-sort {
                color: lighten($reference-color, 30%) !important;
            }
        }
    }
    .table {
        font-family: Lato, sans-serif;
        font-weight: 600;
        width: 100%;
        white-space: nowrap;
        thead {
            tr {
                th {
                    background-color: transparent;
                    position: relative;
                    border-bottom-color: $table-border-color;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    text-align: left;
                    font-weight: bold;
                    color: #fff;
                    span {
                        padding-right: 15px;
                    }
                    svg {
                        &.desc,
                        &.asc {
                            color: $table-sort-color;
                        }
                        &.unsorted {
                            color: lighten($reference-color, 15%);
                        }
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        bottom: 0;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        tbody {
            td,
            th {
                border: none;
                padding: 8px;
            }
            th {
                color: #fff;
            }
            td {
                background-color: transparent;
                color: $default-text-color;
                &:first-child {
                    font-family: Quicksand, sans-serif;
                    color: #fff;
                }
            }
            tr {
                &:hover {
                    td {
                        background-color: var(--accentColor);
                        color: #fff;
                    }
                }
            }
        }
    }
}
