@mixin Tabs {
    .nav-pills {
        &.nav {
            flex-wrap: nowrap !important;
            white-space: nowrap;
            overflow-x: auto;
        }
        .nav-item {
            position: relative;
            margin-bottom: 10px;
            &:first-child {
                .nav-link {
                    cursor: default;
                }
            }
            .nav-link {
                transition: all 150ms ease-in-out;
                padding: 20px;
                color: $default-text-color;
                &.active {
                    color: #fff;
                    background: var(--accentColor);
                }
            }
        }
    }
    .tab-content {
        padding-top: 5px;
        > .tab-pane {
            &.active {
                height: auto;
            }
        }
    }
    .nav-tabs {
        border-radius: 5px;
        background: linear-gradient(90deg, lighten($reference-color, 4%), $content-bg-color);
        white-space: nowrap;
        border: none;
        .nav-link {
            display: inline-block;
            border: none;
            color: $default-text-color;
            transition: color 0.5s linear;
            text-shadow: 0 0 5px #000;
            &.disabled {
                color: lighten($content-bg-color, 25%);
                text-decoration: line-through;
            }
            &.active {
                background: transparent;
                color: #fff;
                border-bottom: 3px solid var(--accentColor);
            }
            &:hover {
                color: #fff;
            }
        }
        &.accent {
            background: linear-gradient(90deg, var(--accentColor), $content-bg-color);
            .nav-link {
                color: $default-text-color;
                &.disabled {
                    color: lighten($content-bg-color, 50%);
                }
                &.active {
                    color: #fff;
                    border-bottom: none;
                }
            }
        }
    }
}
