.navbar {
    padding: 25px 50px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
    z-index: 1000;
    font-size: 16px;
    .navbar-brand {
        color: #fff !important;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
}
.navbar-light {
    background: transparent;
    .navbar-nav {
        .nav-link {
            color: #fff;
            font-weight: bold;
            &:hover {
                color: #00ffbf;
            }
            &.active {
                color: #00ffbf;
            }
        }
    }
}

.bg-navbar-dark {
    background-color: #12121c;
    .navbar-nav {
        .nav-link {
            color: #fff;
            font-weight: bold;
            &:hover {
                color: #00ffbf;
            }
            &.active {
                color: #00ffbf;
            }
        }
    }
}

.navbar-toggler {
    color: #fff !important;
    border-color: transparent !important;
    &:focus {
        color: $reference-color !important;
        border-color: #fff !important;
        background-color: #fff;
        outline: none;
    }
    .navbar-toggler-icon {
        color: rgb(37, 4, 4);
    }
}
.navbar-nav {
    align-items: center;
}
.navbar-collapse {
    z-index: 10000;
    &.collapse.show,
    &.collapsing {
        .navbar-nav {
            border-radius: 5px;
            background: $reference-color;
            padding: 15px;
            text-align: center;
            a {
                border-bottom: 1px dashed lighten($reference-color, 15%);
                &:last-child {
                    border: none;
                }
            }
        }
    }
}
