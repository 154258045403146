@mixin Pagination {
    .pagination {
        .page-item {
            &.active {
                .page-link {
                    color: #fff;
                    background-color: var(--accentColor) !important;
                }
            }
            .page-link {
                background-color: lighten($reference-color, 5%);
                color: $default-text-color;
                border-color: lighten($reference-color, 8%);
                display: inline-block;
                min-width: 35px;
                text-align: center;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    background-color: lighten($reference-color, 13%);
                }
            }
        }
    }
}
