$dark: #2f3c4e;
$content-bg-color: #12121c;
$success-color: #50d187;
$primary-color: #4b4bb9;
$warning-color: #ffac68;
$error-color: #ed145b;
$info-color: #0db8ff;
$clear-color: #3b4366;
$retry-color: #7428a7;

$navbar-top-height: 80px;
$navbar-top-bg-color: #1c2129;
$navbar-top-li-border-color: #eff0f1;
$navbar-top-li-link-color: #4466f2;

// Sidebar
$sidebar-bg-color: #181b1f;
$sidebar-min-width: 90px;
$sidebar-max-width: 218px;

$table-border-color: var(--accentColor);
$table-sort-color: #ccc;

$reference-color: #12121c;
$sidebar-bg-color: lighten($reference-color, 7%);
$navbar-top-bg-color: $reference-color;
$content-bg-color: $reference-color;
$default-text-color: #bcbcf6;

// Chat Status
$online-color: $success-color;
$away-color: $info-color;
$idle-color: $warning-color;

// Scrollbar
$scrollbar-track-color: darken($content-bg-color, 3%);
$scrollbar-thumb-color-hover: $info-color;
$scrollbar-thumb-color: rgba($scrollbar-thumb-color-hover, 0.1);

$window-shadow-size: 10px;
$window-border-radius: $window-shadow-size;
