@mixin Badge {
    .badge {
        color: #fff;
        font-size: 10px;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        text-shadow: 0 0 2px $reference-color;
        &.bg-info {
            background-color: $info-color !important;
        }
        &.bg-success {
            background-color: $success-color !important;
        }
        &.bg-error {
            background-color: $error-color !important;
        }
        &.bg-secondary {
            background-color: $reference-color !important;
        }
        &.bg-primary {
            background-color: var(--accentColor) !important;
        }
        &.bg-default {
            background-color: darken($reference-color, 2%);
            color: lighten($reference-color, 25%);
        }
    }
}
