@mixin Card {
    $card-white-bg-color: #fff;
    $card-black-bg-color: #000;
    $card-blue-bg-color: $info-color;
    $card-warning-bg-color: $warning-color;
    $card-error-bg-color: $error-color;

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        margin-bottom: 15px;
        border: none;
        background-color: lighten($reference-color, 8%);
        background: linear-gradient(lighten($reference-color, 4%), $content-bg-color);
        border-radius: 5px;
        overflow: hidden;

        &.card-transparent {
            background-color: transparent;
            .card-header,
            .card-text,
            .card-footer {
                background-color: transparent;
                color: #fff;
            }
        }
        .card-header {
            z-index: 2;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            border: none;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 10px;
            a {
                color: inherit;
                line-height: normal;
            }
            .badge {
                background-color: lighten($reference-color, 15%);
                font-size: 10px;
                padding: 5px 10px;
                float: right;
            }
            svg.refresh,
            svg.check-all,
            svg.uncheck-all {
                float: right;
                width: 16px;
                height: 16px;
                color: lighten($reference-color, 20%);
                &:hover {
                    color: #fff;
                }
            }
        }
        .card-subtitle {
            font-size: 11px;
            margin-top: 5px;
            color: $default-text-color;
        }
        .card-body {
            position: relative;
            line-height: 20px;
        }
        .card-text,
        .card-footer {
            border: none;
            background-color: transparent;
            color: $default-text-color;
            z-index: 1000;
        }
        &.card-white {
            background-color: $card-white-bg-color;
            .card-header,
            .card-text,
            .card-footer {
                background-color: $card-white-bg-color;
                color: #333;
            }
        }
        .actions {
            color: lighten($reference-color, 40%);
            padding: 5px;
            svg {
                width: 16px;
                height: 16px;
                margin-left: 15px;
                &:hover {
                    color: #fff;
                }
            }
        }
        &.card-info {
            background-color: $card-blue-bg-color;
            .card-header,
            .card-text,
            .card-footer {
                background-color: $card-blue-bg-color;
                color: #fff;
            }
        }
        &.card-warning {
            background-color: $card-warning-bg-color;
            .card-header,
            .card-text,
            .card-footer {
                background-color: $card-warning-bg-color;
                color: #fff;
            }
        }
        &.card-error {
            background-color: $card-error-bg-color;
            .card-header,
            .card-text,
            .card-footer {
                background-color: $card-error-bg-color;
                color: #fff;
            }
        }
    }
}
