@mixin ComponentChartCard {
    $chart-card-height: 62px;
    .chart-card {
        display: flex;
        align-items: center;
        height: $chart-card-height;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        padding: 10px;
        width: 100%;
        background-color: lighten($reference-color, 10%);
        background: linear-gradient(90deg, lighten($reference-color, 4%), $content-bg-color);

        canvas {
            height: 48px;
            position: relative;
            z-index: 1;
        }
        &:hover {
            .icon {
                color: #fff;
                transform: rotateY(360deg);
            }
        }
        .icon {
            transition: all 500ms ease;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-size: 16px;
            width: 48px;
            height: 48px;
            z-index: 0;
            font-family: Lato, sans-serif;
            svg {
                color: var(--accentColor);
                font-size: 16px;
            }
        }
        .info {
            position: absolute;
            bottom: 5px;
            right: 5px;
            svg {
                opacity: 0.25;
                color: #fff;
                &:hover {
                    color: #fff;
                }
            }
        }
        .content {
            width: 100%;
            margin-left: 10px;
            font-family: Quicksand, sans-serif;
            font-size: 12px;
            .header {
                color: #fff;
                font-weight: 700;
            }
            .body {
                font-weight: 600;
                font-family: Lato, sans-serif;
            }
        }
    }
}
