@mixin Hero {
    #hero {
        height: 100vh;
        align-items: center;
        display: flex;
        position: relative;
        overflow: visible;
        z-index: -1;
        .background {
            position: absolute;
            // background-image: url(https://www.desktopbackground.org/download/o/2010/06/01/26615_2560x1440-wallpapers_2560x1600_h.jpg);
            // background: url("../../public/assets/images/starfield-bg.jpg");
            background-size: cover;
            background-attachment: fixed;
            background-position-y: 0px;
            z-index: -1;
            height: 100vh;
            width: 100vw;
            top: 0;
            left: 0;
            video {
                object-fit: cover;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
        .overlay {
            position: absolute;
            background-size: cover;
            z-index: -1;
            height: 1183px;
            width: 100%;
            top: 0;
            left: 0;
        }
        .container-fluid {
            height: 100vh;
            .row {
                height: 100vh;

                .col-4,
                .col-6,
                .col-12 {
                    position: relative;
                    height: 100vh;
                    display: flex;
                    align-items: center !important;
                    .content {
                        p {
                            margin-bottom: 45px;
                        }
                        // @include media-breakpoint-between(xs, sm) {
                        //     text-align: center;
                        //     h1 {
                        //         &:after {
                        //             margin-left: 50%;
                        //             transform: translateX(-50%);
                        //         }
                        //     }
                        // }
                    }
                }
            }
        }
    }
}
