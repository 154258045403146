@mixin ComponentPlayerTab {
    .player-tab {
        font-family: Quicksand, sans-serif;
        margin: -20px;
        font-size: 12px;
        border-radius: 4px;
        position: relative;
        padding: 10px;
        overflow: hidden;
        height: 96px;
        &.overview {
            .content {
                text-shadow: 0 0 5px #000;
                .level {
                    width: auto;
                    display: flex;
                    align-items: center;
                    color: #fff;
                    svg {
                        margin-right: 10px;
                        font-size: 26px;
                    }
                }
            }

            .footer {
                .dps {
                    svg {
                        color: #fff;
                    }
                }
            }
            .map {
                background-size: cover;
                background-position: center;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                opacity: 0.75;
                z-index: 0;
            }
        }

        .gradient {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 0;
            background: linear-gradient(90deg, lighten($reference-color, 4%), $content-bg-color);
        }
        &:hover {
            .gradient {
                background: linear-gradient(90deg, lighten($reference-color, 6%), $content-bg-color);
            }
            .footer {
                background: linear-gradient(90deg, $content-bg-color, lighten($reference-color, 6%));
            }
        }
        &.active {
            .content {
                .avatar {
                    border-color: $reference-color;
                }
                .career-icon {
                    border-color: $reference-color;
                }
                .details {
                    .faction {
                        color: #fff;
                    }
                }
                .level {
                    color: #fff;
                }
            }
            .gradient {
                background: linear-gradient(90deg, var(--accentColor), $content-bg-color);
            }
            .footer {
                background: linear-gradient(90deg, $content-bg-color, var(--accentColor));
                .handle {
                    color: #fff;
                }
            }
        }
        .content {
            position: relative;
            z-index: 1;
            .avatar {
                margin-right: 10px;
                transform: rotate(-45deg);
                border-color: lighten($reference-color, 15%);
                border-width: 2px;
                background-color: transparent;

                &.Science {
                    border-color: #2992e2;
                }
                &.Tactical {
                    border-color: #e22973;
                }
                &.Engineer {
                    border-color: #e2e229;
                }
            }
            .details {
                overflow: hidden;
                width: 100%;
                .name {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .faction {
                    color: #7f7fbf;
                    font-weight: 600;
                }
                .map-info {
                    color: #fff;
                    font-weight: 600;
                }
            }
            .level {
                font-size: 36px;
                width: 48px;
                flex-shrink: 0;
                color: lighten($default-text-color, 3%);
                font-family: Lato, sans-serif;
                text-align: right;
            }
        }
        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(90deg, $content-bg-color, lighten($reference-color, 4%));
            width: 100%;
            font-size: 12px;
            padding: 5px 10px;
            .handle {
                color: #bcbcf6;
                width: 50%;
                font-size: 12px;
            }
            .dps {
                color: #fff;
                font-weight: bold;
                text-align: right;
                font-family: Lato, sans-serif;
                width: 50%;
                svg {
                    color: #ff7700;
                    margin-left: 5px;
                }
            }
        }
    }
}
