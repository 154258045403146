@import "./Components/features";
@import "./Components/statistics";
@import "./Components/hero";
@import "./Components/download_banner";
$normal-color: rgba(#12121c, 0.95);
#landing-page {
    height: 100%;
    font-size: 16px;
    line-height: 30px;
    .content-wrapper {
        position: relative;
        z-index: 1;
    }
    @include Hero;
    @include Features;
    @include ComponentStatistics;
    @include DownloadBanner;
}
