@mixin Jumbotron {
    .jumbotron {
        position: relative;
        background-color: lighten($reference-color, 2%) !important;
        overflow: hidden;
        line-height: 23px;
        border-radius: 5px;
        padding: 15px;
        .jumbotron-header {
            color: #fff;
            font-weight: bold;
            span {
                color: var(--accentColor);
            }
        }
        .jumbotron-body {
            font-size: 12px;
        }
        .jumbotron-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
        }
    }
}
