@mixin Toast {
    $toast-error-color: #ff0058;
    $toast-info-color: #0077ff;
    $toast-success-color: #13a98a;
    $toast-warning-color: #de775b;
    .toast {
        position: relative;
        z-index: 10000;
        overflow: hidden;
        font-size: 1rem;
        box-shadow: 0 0 10px #000;
        border: none;
        margin-top: 10px;
        .toast-header {
            color: #fff;
            button {
                &.close {
                    color: #fff;
                }
            }
        }
        .toast-body {
            color: #fff;
        }
        &.bg-error,&.bg-danger {
            .toast-header,
            .toast-body {
                background-color: $toast-error-color;
            }
        }
        &.bg-success {
            .toast-header,
            .toast-body {
                background-color: $toast-success-color;
            }
        }
        &.bg-info {
            .toast-header,
            .toast-body {
                background-color: $toast-info-color;
            }
        }
        &.bg-warn {
            .toast-header,
            .toast-body {
                background-color: $toast-warning-color;
            }
        }
        &.bg-primary {
            .toast-header,
            .toast-body {
                background-color: var(--accentColor);
            }
        }
        .toast-header {
            border: none;
            background-color: $reference-color;
        }
        background-color: $reference-color;
    }
}
