$reference-color: #12121c;
$default-text-color: #bcbcf6;
$content-bg-color: #12121c;
$info-color: #06f;
$success-color: #335200;
$error-color: #f00;
$warning-color: #990;
$table-sort-color: #600;

$scrollbar-track-color: darken($content-bg-color, 3%);
$scrollbar-thumb-color-hover: $info-color;
$scrollbar-thumb-color: rgba($scrollbar-thumb-color-hover, 0.1);
