@mixin Alert {
    .alert {
        margin-top: 10px;
        &.alert-default {
            color: #fff;
            border-color: $reference-color;
            background-color: $reference-color;
        }
        &.alert-info {
            color: #fff;
            border-color: $info-color;
            background-color: $info-color;
        }
        &.alert-info {
            color: #fff;
            border-color: $info-color;
            background-color: $info-color;
        }
        &.alert-secondary {
            color: #fff;
            border-color: lighten($reference-color, 20%);
            background-color: lighten($reference-color, 20%);
        }
        code {
            background-color: lighten($reference-color, 10%);
            border-radius: 50px;
            margin: 0 5px;
            padding: 5px 10px;
            color: #ff0;
        }
        .close {
            color: #fff !important;
            cursor: default;
        }
        button {
            outline: 0;
        }
        &.alert-error {
            color: #fff;
            border-color: $error-color;
            background-color: $error-color;
        }

        &.alert-success {
            color: #fff;
            border-color: $success-color;
            background-color: $success-color;
        }
        &.alert-accent {
            color: #fff;
            border-color: var(--accentColor);
            background-color: var(--accentColor);
        }

        &.alert-warning {
            color: #fff;
            border-color: $warning-color;
            background-color: $warning-color;
        }
    }
}
