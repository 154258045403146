@mixin Form {
    form {
        .form-text {
            &.text-muted {
                margin-left: 27px;
                font-size: 12px;
                color: darken($default-text-color, 5%) !important;
                font-weight: 600;
                display: block;
            }
        }
        .form-check-label {
            font-weight: bold;
            margin-left: 10px;
            color: #fff;
        }
        .form-check-input {
            border: none;
            outline: none;
        }
        input[type="color" i] {
            cursor: default !important;
            width: 25px;
            padding: 0;
        }
        input[type="checkbox"] {
            height: 14px;
            width: 14px;
            position: relative;
            -webkit-appearance: none;
        }

        input[type="checkbox"]:before,
        input[type="checkbox"]:after,
        input[type="checkbox"]:checked:before,
        input[type="checkbox"]:checked:after {
            content: "";
            display: inline-block;
            position: absolute;
            box-sizing: border-box;
            height: 14px;
            width: 14px;
            border-radius: 2px;
            border: 1px solid lighten($reference-color, 15%);
            background-color: $reference-color;
        }
        input[type="checkbox"]:checked {
            position: relative;
        }
        textarea {
            min-height: 63px;
            max-height: 460px;
        }
        input[type="checkbox"]:checked:after {
            top: 50%;
            left: 50%;
            height: 10px;
            width: 10px;
            border-radius: 1px;
            border: none;
            background-color: var(--accentColor);
            transform: translate(-50%, -50%);
        }

        .form-group {
            label {
                text-transform: capitalize;
            }
        }
        .form-control {
            font-size: 1em;
            background-color: lighten($reference-color, 2%);
            color: #fff;
            border: none;
            margin: 5px 0;
            padding: 20px 25px;
            border-bottom: 3px solid lighten($reference-color, 5%);
            &:focus {
                background-color: lighten($reference-color, 5%);
                color: #fff;
                box-shadow: none;
                border-bottom: 3px solid var(--accentColor);
            }
            &.error {
                border-bottom: 3px solid $error-color;
            }
            &.success {
                border-bottom: 3px solid $success-color;
            }
        }
        .custom-select {
            border-color: lighten($reference-color, 15%);
            color: #fff;
            padding: 10px;
            height: auto;
            background-color: lighten($reference-color, 5%);
            &:focus,
            &:active {
                box-shadow: none;
                border-color: lighten($reference-color, 30%);
            }
        }
        input,
        textarea {
            outline: none !important;
            &::placeholder {
                color: lighten($reference-color, 50%) !important;
                opacity: 1;
            }
        }

        .input-group-append {
            width: 100%;
            position: relative;
        }
        .input-group {
            text-align: left;
        }
        .show-password {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            outline: 0;
            z-index: 1000;
            color: lighten($reference-color, 10%);
            font-size: 1.4em;

            &:hover,
            &:focus,
            &:active {
                color: #fff;
            }
        }
        button {
            &:hover {
                background-color: lighten($info-color, 5%);
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            border: none;
            padding: 10px;
            margin: 5px 0;
            color: #fff;
            background-color: $info-color;
        }
    }
    select {
        option {
            border-radius: 5px;
            padding: 10px;
        }
    }
}
